import React, { createContext, FC, useReducer } from "react";
import { getItem } from "../utils/get-item";
import { setItem } from "../utils/set-item";

export const GlobalThemeState = createContext<ThemeEnum>("dark");
export const GlobalThemeDispatch = createContext<React.Dispatch<any>>(
  {} as React.Dispatch<any>
);

const THEME_KEY = "OBFY_THEME";

export const themeActions = {
  SET_THEME: "SET_THEME",
};
type ThemeEnum = "dark" | "light";

function themeReducer(
  state: ThemeEnum,
  action: { type: string; payload?: any }
) {
  switch (action.type) {
    case themeActions.SET_THEME:
      setItem(THEME_KEY, action.payload);
      return action.payload;
    default:
      return state;
  }
}

export const GlobalThemeProvider: FC<any> = ({ children }) => {
  const [state, dispatch] = useReducer(
    themeReducer,
    getItem(THEME_KEY) || "dark"
  );

  return (
    <GlobalThemeState.Provider value={state}>
      <GlobalThemeDispatch.Provider value={dispatch}>
        {children}
      </GlobalThemeDispatch.Provider>
    </GlobalThemeState.Provider>
  );
};
