import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { InstagramIcon } from "../../assets/icons/ig";
import { TwitterIcon } from "../../assets/icons/twitter";

import { generateUniqueKey } from "../../utils/generate-unique-key";
import { Player } from "../player-card/player-card";
import {
  ChartPie,
  Info,
  User,
} from "lucide-react";
import { getPlayerById } from "../../utils/get-contenders";
import { FirebaseApp } from "../../firebase/firebase";
import { LoadingPage } from "../loading/loading";

export const PlayerPage = () => {
  let { id } = useParams();
  const [activeStatus, setActiveStatus] = useState("Stats");
  const [content, setContent] = useState<React.ReactNode>();
  const [player, setPlayer] = useState({} as Player);
  const [playerImg, setPlayerImg] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    async function fetchPlayer() {
      const user = await getPlayerById(id as string);
      const playerImgUrl = await FirebaseApp.downloadImg(user.img as string);
      setPlayer(user);
      setPlayerImg(playerImgUrl);
      setLoading(false);
    }
    fetchPlayer();
  }, [id]);
  const tabs = [
    {
      icon: (
        <ChartPie
          className={`h-4 w-4 ${
            activeStatus === "Stats"
              ? "stroke-red-500"
              : "stroke-shark-700 dark:stroke-shark-200 group-hover:stroke-red-500"
          }`}
        />
      ),
      title: "Stats",
      content: (
        <div className="flex gap-2">
          <div className="text-xs min-w-full min-h-full">
            Handicap: {player.handicap || "--"}
          </div>
        </div>
      ),
    },

    {
      icon: (
        <Info
          className={`h-4 w-4 ${
            activeStatus === "Wiki"
              ? "stroke-red-500"
              : "stroke-shark-700 dark:stroke-shark-200 group-hover:stroke-red-500"
          }`}
        />
      ),
      title: "Wiki",
      content: <div></div>,
    },
    {
      icon: (
        <User
          className={`h-4 w-4 ${
            activeStatus === "Personal"
              ? "stroke-red-500"
              : "stroke-shark-700 dark:stroke-shark-200 group-hover:stroke-red-500"
          }`}
        />
      ),
      title: "Personal",
      content: <div></div>,
    },
  ];
  if (loading) return <LoadingPage />;
  return (
    <div className="max-w-2xl min-h-full mt-2 mx-auto">
      <div className=" flex gap-2">
        <img
          alt="Cage"
          className={`border ${
            player.committed ? "border-green-500" : "border-red-500"
          } rounded-full w-40 aspect-square`}
          src={playerImg || `https://www.placecage.com/c/300/300`}
        />
        <div className="info flex flex-col gap-1">
          {!player.committed && (
            <div className="text-red-500  uppercase font-bold text-2xl">
              {player.status}
            </div>
          )}
          <div className="flex gap-1 items-baseline ">
            {player.name}
            {player.aka && (
              <small className="block p-0 leading-none text-blue-500">
                {player.aka}
              </small>
            )}
          </div>
          <div className="flex gap-2 items-center">
            <small
              className={`text-xs ${
                Number(player.handicap) < 5
                  ? "text-green-500"
                  : Number(player.handicap) < 10
                  ? "text-blue-500"
                  : Number(player.handicap) < 20
                  ? "text-yellow-500"
                  : "text-red-500"
              }`}
            >
              <span className=" text-white">hcp: </span>
              {Number(player.handicap)}
            </small>
          </div>
          <div className="flex gap-2 items-center">
            {player.ig && (
              <a
                className="z-50 cursor-pointer"
                href={player.ig}
                target="_blank"
                referrerPolicy="no-referrer"
                rel="noreferrer"
              >
                <InstagramIcon />
              </a>
            )}
            {player.twitter && (
              <a
                className="z-50 cursor-pointer"
                href={player.twitter}
                target="_blank"
                referrerPolicy="no-referrer"
                rel="noreferrer"
              >
                <TwitterIcon />
              </a>
            )}
          </div>
        </div>
      </div>
      <ul className="flex px-5">
        {tabs.map((tab) => {
          const isActive = activeStatus === tab.title;
          return (
            <li
              key={generateUniqueKey("tab")}
              onClick={() => {
                setActiveStatus(tab.title);
                setContent(tab.content);
              }}
              className={
                isActive
                  ? "group text-sm border-red-500 pt-3 rounded-t text-red-500 mr-12"
                  : "group text-sm text-shark-600 dark:text-shark-200 py-3 flex items-center mr-12 hover:text-red-500 cursor-pointer"
              }
            >
              <div className={`flex items-center mb-3 `}>
                {tab.icon}

                <span className="ml-1 font-normal">{tab.title}</span>
              </div>
              {isActive && (
                <div className="w-full h-1 bg-red-500 rounded-t-md" />
              )}
            </li>
          );
        })}
      </ul>

      {content || tabs.find((t) => t.title === activeStatus)?.content || null}
    </div>
  );
};
