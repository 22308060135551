import { useState } from "react";
import { FirebaseApp } from "../../firebase/firebase";
import { generateUniqueKey } from "../../utils/generate-unique-key";

export interface Player {
  id: string;
  name: string;
  status: string;
  url: string;
  linkedIn?: string;
  wiki?: string;
  spotify?: string;
  facebook?: string;
  committed?: boolean;
  ig?: string;
  twitter?: string;
  aka?: string;
  website?: string;
  img?: string;
  handicap?: string | number;
}
interface Props {
  player: Player;
}
export const PlayerCard = (props: Props) => {
  const { player } = props;

  const [playerImg, setPlayerImg] = useState<string | null>(null);
  if (player?.img) {
    FirebaseApp.downloadImg(player?.img).then((url) => {
      setPlayerImg(url);
    });
  }
  return (
    <div
      key={generateUniqueKey("contender-list")}
      className={`relative rounded shadow capitalize `}
    >
      {!player?.committed && (
        <div className="absolute top-0 bottom-0 left-0 right-0">
          <div
            className={` max-w-fit text-offWhite-100  text-xs rounded-tl rounded-br px-2 py-1 uppercase ${
              player?.status.includes("unknown")
                ? "bg-orange-600 "
                : "bg-red-600"
            }`}
          >
            {player?.status}
          </div>
        </div>
      )}
      <img
        alt="Cage"
        className="rounded-t-md w-full aspect-square"
        src={playerImg || `https://www.placecage.com/c/300/300`}
      />
      <div
        className={`w-full bg-shark-800 rounded-b-md px-2 py-3 gap-2 flex justify-between items-center`}
      >
        <span className="whitespace-nowrap">
          {player?.name}
          {player?.handicap ? (
            <small
              className={`text-xs ml-1 ${
                Number(player?.handicap) < 5
                  ? "text-green-500"
                  : Number(player?.handicap) < 10
                  ? "text-blue-500"
                  : Number(player?.handicap) < 20
                  ? "text-yellow-500"
                  : "text-red-500"
              }`}
            >
              <span className="pl-1 text-white">hcp: </span>
              {Number(player?.handicap)}
            </small>
          ) : (
            "--"
          )}
          {player?.aka && (
            <small className="block p-0 leading-none text-offWhite-700">
              {player?.aka}
            </small>
          )}
        </span>
        <div className="hidden md:flex  gap-2 z-20 ">
          {/* {player?.ig && (
            <a
              rel="noreferrer"
              className="z-50 cursor-pointer"
              href={player?.ig}
              target="_blank"
              referrerPolicy="no-referrer"
            >
              <InstagramIcon />
            </a>
          )}
          {player?.twitter && (
            <a
              rel="noreferrer"
              className="z-50 cursor-pointer"
              href={player?.twitter}
              target="_blank"
              referrerPolicy="no-referrer"
            >
              <TwitterIcon />
            </a>
          )} */}
        </div>
      </div>
    </div>
  );
};
